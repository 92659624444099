<template>
  <div id="myCat">
    <h1>Meow</h1>
    <h2><strong>DO NOT</strong> hover on me</h2>
    <div class="cat-wrap">
        <div class="do-not">
            <p>Oh NOooo.....Look what you did</p>
            <pre class="broken-cat">
                                                      
                               (#%                
                              %#%$(               
                 .(##       (#%####               
                   #(#%%%(((%#%###@               
                    ###/(//(##@@%##@              
 .(##**(%            */#@#*/####%#@#              
 ##%(###%(           //(**/%%(#(##%#              
  ,/(#%##(((          ,***/########%#(    ****/   
   ((((#(((##*      .,*****/*/(#%%#%##@ *(****/(# 
        /(#(((/    .,**/,*,///((/%%%%#%(/(,*,*/(##
          //((((/*,,,,*,,,,*/((((/(#%##%%##,,/(((#
           .//(/((////(*,,//#%#(((((/*/(#%#,*/((((
             *///((//((*,**/(%##(##/***/%%%%**((((
               */((/((/**/#%##%#%#((///(((%%%.//((
                ,//(((*/*//%#%@##%(#%####(##      
                 ,/(#%#(/*/(##%#%%##%%%#(#(#%     
                 */(%#//((((/(###%%##%(#/#%##/((# 
                **//(*(((/******,*******(#(*/((###
                */(%(/((###(/**,********///*///(((
                *//((/((((/**/**//////////**/////*
            </pre>
        </div>
        <img class="img" src="../assets/img/whiskey.jpg" alt="Whiskey Photo Say Meow">
    </div>
    
    <p>Bonjour, I'm Whiskey!</p>
    <p class="fs-1">See my full story and more photos on my <a href="https://www.instagram.com/whiskey.meow/" target="_blank">Instagram</a>.</p>
    <router-link to="/">
        <img  src="../assets/img/exit-ico.png" alt="Go back home">
    </router-link>
</div>
</template>

<style lang="less" scoped>
    #myCat {
        .cat-wrap {
            .do-not {
                opacity: 0;
                height: 0;
                transition: opacity 0.5s ease-in-out;
            }
            img {
                transition: opacity 0.5s ease-in-out;
            }
        }
        h2:hover + .cat-wrap {
            .img {
                height: 0;
                opacity: 0;
            }
            .do-not {
                opacity:1;
                height: auto;
            }
        }
        h1 {
            font-size: 5em;
            color: #fff;;
            display: block;
            text-shadow: 0px 0px 3px black; 
            text-transform: uppercase;
            margin-bottom: 0;
        }
        h2 {
            cursor: not-allowed;
            font-size: 1em;
            margin-top: 0;
            strong {
                text-decoration: underline;
            }
        }
        .img {
            border-bottom: 1px solid orange;
            padding: 0 3em;
            height: 332px;
        }
        p {
            font-size: 2em;
            &.fs-1 {
                font-size: 1em;
            }
        }
        a {
            color: orange;
        }
    }
</style>